import { Space } from "antd";
import { Guid } from "guid-typescript";
import React, { PureComponent, ReactNode } from "react";
import withCommonEvents from "../../../shared/hoc/with-common-events";
import DynamicStyleComponent from "../../dynamic-styled-component-wrapper";
import { CommonProps } from "../common-props";
import "./horizontal-stack.scss"

interface HorizontalStackProps {
  style: React.CSSProperties;
  size?: number;
}

interface HorizontalStackState {
  uniqueKey: Guid;
}

declare let window: any;

class HorizontalStack extends PureComponent<HorizontalStackProps & CommonProps, HorizontalStackState> {
  constructor(props: HorizontalStackProps) {
    super(props);
    this.state = {
      uniqueKey: Guid.create()
    };
  }

  getProps = () => {
    const props: any = { ...this.props };
    if (props.children) {
      delete props.children;
    }
    if (props.style) {
      delete props.style;
    }
    if (props.size < 0) props.size = 0;
    return props;
  };

  getStyleProp = () => {
    let style: React.CSSProperties = {};

    if (this.props.style) {
      style = { ...this.props.style };
    }
    style.height = "100%";
    style.width = this.props.children ? "max-content" : "100%";

    if (!style.alignItems) {
      style.alignItems = "unset";
    }

    return style;
  };

  getDynamicCss = (): string => {
    const className: string = this.getClassName();
    let result = "";

    const { size } = this.props;
    if (size && size < 0) {
      result += `.ant-space-item:not(:first-of-type) {
          margin-left: ${size}px !important;
        }`;
    }
    return result;
  };

  getClassName = () => {
    let result = "";
    if (this.props.className) {
      result = this.props.className;
    }
    if (!this.state.uniqueKey) {
      return result;
    }
    result = `${result} kspace_${this.state.uniqueKey.toString().substring(0, 8)}`;
    return result;
  };

  render(): ReactNode {
    const dynamicStyles = this.getDynamicCss();
    return (
      <DynamicStyleComponent dynamicStyles={dynamicStyles} className={this.getClassName()}>
        <div style={{ overflowX: "auto", overflowY: "hidden" }}>
          <Space
            className={this.getClassName()}
            style={this.getStyleProp()}
            {...this.getProps()}
            direction="horizontal"
          >
            {React.Children.map(this.props.children, (child: any) => {
              if (child?.props?.visibility === "hidden" || child?.props?.children?.props?.visibility === "hidden") {
                return null;
              }
              return <>{child}</>;
            })}
          </Space>
        </div>
      </DynamicStyleComponent>
    );
  }
}

const horizontalStack = withCommonEvents(HorizontalStack);
export { horizontalStack as HorizontalStack };
