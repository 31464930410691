import _ from "lodash";
import { PureComponent, ReactNode } from "react";

interface TableHeaderCellProps {
  style?: any;
  columnStyle?: any;
  textDirection?: string;
}

interface TableHeaderCellState {}

export class TableHeaderCell extends PureComponent<TableHeaderCellProps, TableHeaderCellState> {
  constructor(props: TableHeaderCellProps) {
    super(props);
  }

  getStyle = (): any => {
    let style: any = {};
    if (this.props.style) {
      style = _.clone(this.props.style);
    }
    if (
      !style.border &&
      !style.borderColor &&
      !style.borderTop &&
      !style.borderBottom &&
      !style.borderRight &&
      !style.borderLeft &&
      !style.borderWidth
    ) {
      style.borderWidth = 0;
    }
    if (!style.backgroundColor && !style.background) {
      style.backgroundColor = "transparent";
    }
    if (this.props.columnStyle && this.props.columnStyle.textAlign) {
      style.textAlign = this.props.columnStyle.textAlign;
    }
    if (this.props.textDirection && this.props.textDirection !== "Default") {
      if (style.textAlign === "-webkit-left") {
        style.verticalAlign = this.props.textDirection === "Rotate Up" ? "bottom" : "top";
      } else if (style.textAlign === "-webkit-right") {
        style.verticalAlign = this.props.textDirection === "Rotate Up" ? "top" : "bottom";
      } else {
        style.verticalAlign = "middle";
      }
      style.textAlign = "-webkit-center";
    }
    if (this.props.columnStyle && this.props.columnStyle.padding) {
      style.paddingLeft = this.props.columnStyle.padding;
      style.paddingRight = this.props.columnStyle.padding;
    }
    if (this.props.columnStyle && this.props.columnStyle.paddingLeft) {
      style.paddingLeft = this.props.columnStyle.paddingLeft;
    }
    if (this.props.columnStyle && this.props.columnStyle.paddingRight) {
      style.paddingRight = this.props.columnStyle.paddingRight;
    }
    if (style?.display) {
      delete style.display;
    }
    return style;
  };

  render(): ReactNode {
    return <th {...this.props} style={this.getStyle()} />;
  }
}
