import _ from "lodash";
import { PureComponent, ReactNode } from "react";

interface TableViewWrapperProps {
  style?: any;
}

interface TableViewWrapperState {}

export class TableViewWrapper extends PureComponent<TableViewWrapperProps, TableViewWrapperState> {
  constructor(props: TableViewWrapperProps) {
    super(props);
    this.state = {};
  }

  getStyle = (): any => {
    let result: any = {};
    if (this.props.style) {
      result = _.clone(this.props.style);
    }
    result.borderCollapse = "collapse";
    if (result?.display) {
      delete result.display;
    }
    result.height = "100%";
    return result;
  };

  render(): ReactNode {
    return <table {...this.props} style={this.getStyle()} />;
  }
}
