import { ColumnType } from "antd/lib/table";
import _ from "lodash";
import React, { PureComponent } from "react";
import withCommonEvents from "../../../../shared/hoc/with-common-events";
import { ReactSystemFunctions } from "../../../../shared/utilty/react-system-functions";
import { CommonProps } from "../../common/common-props";

export interface TableDataProps extends ColumnType<any> {
  rowData?: any;
  children?: React.ReactNode;
  isEditableColumn?: boolean;
  isEditMode?: boolean;
}
declare let window: any;
interface KTableDataState {}

class TableData<P, S> extends PureComponent<TableDataProps & P & CommonProps, KTableDataState & S> {
  constructor(props: TableDataProps & P & CommonProps) {
    super(props);
  }

  static dataFieldKey: RegExp = /\[datafield:((?:(?!\[datafield:).)*)\]/;

  static oldValue: RegExp = /\[datafield:((?:(?!\[datafield:).)*)\]/g;

  static toCamelCase = (str: string): string => {
    return str.replace(/^([A-Z])|[\s-](\w)/g, (match, p1, p2) => {
      if (p2) {
        return p2.toUpperCase();
      }

      return p1.toLowerCase();
    });
  };

  static getValueFromPath = (data: object, path: string, index: number) => {
    // Example path: "Users[first].Name
    const fields: string[] = path
      .split(".")
      .filter((field: string, index: number, array: string[]) => {
        const theFieldIndexContaingSelected = array.findIndex((x) => x.includes("[selected]"));

        if (theFieldIndexContaingSelected > -1) {
          return index > theFieldIndexContaingSelected;
        }

        return true;
      })
      .map((x) => TableData.toCamelCase(x));

    const value = fields.reduce((prev: any, curr: string) => {
      if (!prev) return "";
      let result = null;

      if (curr.endsWith("[first]")) {
        const field: string = curr.replace("[first]", "");
        result = prev?.[field]?.at?.(0);
      } else if (curr.endsWith("[last]")) {
        const field: string = curr.replace("[last]", "");
        result = prev?.[field]?.at?.(-1);
      } else if (curr.endsWith("[selected]")) {
        const field: string = curr.replace("[selected]", "");
        result = prev?.[field]?.at?.(index);
      } else {
        const field: string = curr;
        result = prev?.[field];
      }

      return result;
    }, data);

    return value;
  };

  static getIsDataRepeater = (child: any): boolean => {
    if (!child) return false;

    if (child?.props?.dataSource && child.props.dataSource?.length > 0) {
      return true;
    }

    return false;
  };

  static setDataFieldValues = (children: any, rowData?: any, rowIndex?: number): React.ReactNode[] => {
    if (!children) return [];

    return React.Children.map(children, (child, childIndex) => {
      const props: Record<string, any> = {};
      let manipulatedChildren: React.ReactNode[] = [];

      if (child?.props) {
        let { visExField1 } = child.props;
        if (visExField1) {
          const matchResult = child.props.visExField1.toString().match(TableData.dataFieldKey);
          if (matchResult) {
            const dataField = matchResult[1];
            const newValue = this.getValueFromPath(rowData, dataField, childIndex) ?? "";
            visExField1 = visExField1.replace(TableData.oldValue, newValue);
          }
        }
        let { visExField2 } = child.props;
        if (visExField2) {
          const matchResult = child.props.visExField2.toString().match(TableData.dataFieldKey);
          if (matchResult) {
            const dataField = matchResult[1];
            const newValue = this.getValueFromPath(rowData, dataField, childIndex) ?? "";
            visExField2 = visExField2.replace(TableData.oldValue, newValue);
          }
        }
        const { visExRule } = child.props;
        if (!_.isUndefined(visExField1) && !_.isUndefined(visExField2) && !_.isUndefined(visExRule)) {
          if (child?.props?.visibilityByAuthorization === "hidden") {
            props.visibility = "hidden";
          } else {
            props.visibility =
              ReactSystemFunctions[visExRule](visExField1, visExField2) === true ? "visible" : "hidden";
          }
        }

        Object?.keys(child.props)?.forEach((key) => {
          if (typeof child.props[key] === "string") {
            if (child.props[key]?.toString().indexOf("datafield:") > -1) {
              const matchResult = child.props[key]?.toString().match(TableData.dataFieldKey);
              if (rowData && matchResult && matchResult?.length > 0) {
                const dataField = matchResult[1];
                let newValue = this.getValueFromPath(rowData, dataField, rowIndex) ?? "";

                if (Array.isArray(newValue) || _.isObject(newValue)) {
                  props[key] = newValue;
                } else {
                  props[key] = child.props[key]?.toString().replace(TableData.oldValue, newValue);
                }

                if (
                  !window ||
                  !window.kuika ||
                  (window.kuika?.dashboardState !== 1 && window.kuika?.dashboardState !== 17)
                ) {
                  if (props[key] == "" || props[key] == "null" || props[key] == "undefined") {
                    props[key] = " ";
                  }
                }
              }
            }

            if (key === "children") {
              manipulatedChildren.push(props.children);
            }
          } else if (key === "children") {
            if (TableData.getIsDataRepeater(child)) {
              return;
            }

            manipulatedChildren = TableData.setDataFieldValues(child.props.children, rowData, rowIndex);
          }

          if (!props[key]) {
            props[key] = child.props[key];
          }
        });
      }

      props.rowIndex = rowIndex;

      if (manipulatedChildren?.length > 0) {
        return React.cloneElement(child, props, manipulatedChildren);
      }

      return React.cloneElement(child, props, child.props.children);
    });
  };

  render = () => {
    if (this.props.visibility === "hidden") return <></>;
    return TableData.setDataFieldValues(this.props.children, this.props.rowData, this.props.rowIndex);
  };
}

const tableData = withCommonEvents<TableDataProps & CommonProps, KTableDataState>(TableData);
export { tableData as TableData };
